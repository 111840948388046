@mixin box() {
  padding: 0.8rem;
  margin: 1rem;
  background-color: rgb(33, 33, 33);
  outline: 0.2rem groove;
  outline-color: grey;
  border-radius: 0.5em;
}

@mixin center-stuff(){
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.current {
    color: rgb(255, 255, 255);
  }

.centered_text {
    text-align: center;
}

a {
    color: rgb(114, 114, 114);
}

html {
  color-scheme: dark;
  color: rgb(217, 217, 217);
  background-color: rgb(0, 0, 0);
  font-size: 1em;
  font-family: Verdana;
  line-height: 1.5;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

header {
  @include center-stuff();
}

footer {
  @include center-stuff();
}

main {
  @include box;
}

.menu {
  @include box;
  
  a {
    padding: 0.5em;
  }
}

.copyright {
  @include box;
  text-align: center;
}